import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  additionalRequestItem,
  GetAdditionalRequestOverviewData,
  ProcessAdditionalRequestOverviewInput,
} from "../../types";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import moment from "moment";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import { Col, Row } from "react-bootstrap";
import {
  Additionalwork_status_types,
  Additional_Request_Approver,
} from "../../utility/constants";
import * as Yup from "yup";
import {
  getAdditionalRequestOverview,
  submitAdditionalRequestOverviewData,
} from "../../services/additionalRequestOverviewService";
import { Form, Formik } from "formik";
import AppTextAreaInput from "../components/forms/AppTextAreaInput";
import AppButton from "../components/Common/AppButton";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import AppTextInput from "../components/forms/AppTextInput";
import { AppSignaturepad } from "../components/forms/AppSignaturepad";
import HeaderBanner from "../components/banners/HeaderBanner";
import useWeekPart from "../../utility/hooks/useWeekPart";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";

const validationSchema = Yup.object().shape({
  additionalRequestCategories: Yup.array().of(
    Yup.object().shape({
      additionalRequestItems: Yup.array().of(
        Yup.object().shape({
          quantity: Yup.number().positive().max(10000).label("Amount"),
        })
      ),
    })
  ),
});

const AdditionalHoursOverviewSheet = () => {
  const [loading, setLoading] = useState(false);
  const [dateInfo, calculateWeekPart] = useWeekPart();

  const [saving, setSaving] = useState(false);
  const [dataEntryInComplete, setDataEntryInComplete] = useState(false);

  const { role, id } = useParams();

  const projectId = parseInt(id!);
  const additionalRequestApproverId = parseInt(role!);

  const [additionalHoursData, setAdditionalHoursData] =
    useState<GetAdditionalRequestOverviewData>();

  const { hasSecondWeekPart, weekPart, week, year } = additionalHoursData || {};

  async function fetchData(
    year: number,
    week: number,
    weekPart: number,
    projectId: number
  ) {
    setLoading(true);
    const result = await getAdditionalRequestOverview(
      projectId,
      week,
      weekPart,
      year!
    );

    if (result) {
      setAdditionalHoursData(result.data.result);
    }

    setLoading(false);
  }

  useEffect(() => {
    // Get current date
    const currentDate = moment();

    // If we're in the first ISO week, return the new year (next year)
    const year =
      currentDate.isoWeek() === 1 && currentDate.month() === 11
        ? currentDate.year() + 1
        : currentDate.year(); // Keep the current year otherwise

    fetchData(year, moment().isoWeek(), 1, projectId);
  }, []);

  const handleSubmit = (
    data: ProcessAdditionalRequestOverviewInput,
    { setSubmitting }
  ) => {
    submitAdditionalRequestOverviewData(data)
      .then(() => {
        Notify.success(`Overview saved`);

        setSubmitting(false);

        fetchData(year!, week!, weekPart!, projectId);
      })
      .catch(() => {
        Message("Saving data failed");
      });
  };

  const handleNextPrevDate = (isPrevious: boolean) => {
    const newData = calculateNextPrevWeek(
      isPrevious,
      week!,
      weekPart!,
      hasSecondWeekPart!,
      year!
    );

    fetchData(newData.year, newData.week, newData.weekPart, projectId);
  };

  const dataEntryDisabled = (): boolean => {
    if (additionalHoursData?.additionalRequestCategories.length === 0)
      return true;
    if (
      additionalRequestApproverId === Additional_Request_Approver.AreaManager &&
      additionalHoursData?.status === Additionalwork_status_types.SendForReview
    ) {
      return false;
    }

    if (
      additionalRequestApproverId ===
        Additional_Request_Approver.HotelManager &&
      additionalHoursData?.status ===
        Additionalwork_status_types.ApprovedByAreaManager
    )
      return false;

    return true;
  };

  const handleDatePickerChange = async (newDate) => {
    const result = await calculateWeekPart(newDate);

    // Now call fetchData with the new year, week, weekPart, and the existing filters
    fetchData(
      result.selectedYear!,
      result.selectedWeek!,
      result.weekPart!,
      projectId
    );
  };

  const getAmountType = (input: additionalRequestItem) => {
    if (input.isNumberOfRooms) return "Amount of rooms";

    if (!input.isNumberOfRooms && !input.numberOfMinutes)
      return "Amount of hours";

    return "Quantity " + input.numberOfMinutes + " minutes";
  };
  if (!additionalHoursData) {
    return <p>Er is geen data gevonden</p>;
  }

  const initialValues: ProcessAdditionalRequestOverviewInput = {
    purchaseOrder: additionalHoursData.purchaseOrder,
    comment: "",
    isApproved: additionalHoursData.isApproved,
    overviewId: additionalHoursData.overviewId,
    projectId: additionalHoursData.projectId,
    signature: "",
    approver: parseInt(role!),
  };

  return (
    <>
      <PageTitle title="Additional requests" customStyles="mb-2" />

      <DateToggler
        title={
          hasSecondWeekPart || weekPart === 2 ? (
            <span className="mx-3">
              Additional requests - week {week}.{weekPart}
            </span>
          ) : (
            <span className="mx-3">Additional request - week {week}</span>
          )
        }
        onNextDate={() => handleNextPrevDate(false)}
        onPreviousDate={() => handleNextPrevDate(true)}
        handleDatePickerChange={(date) => handleDatePickerChange(date)}
      />

      {additionalHoursData && (
        <>
          {additionalRequestApproverId ===
            Additional_Request_Approver.HotelManager && (
            <>
              {additionalHoursData.status ===
                Additionalwork_status_types.DeclinedByHotelManager && (
                <HeaderBanner
                  title="Overview declined"
                  message="This overview has been sent back to the housekeeper because it is declined by hotelmanager"
                  type="danger"
                />
              )}
              {additionalHoursData.status ===
                Additionalwork_status_types.ApprovedByHotelManager && (
                <HeaderBanner title="Overview approved" type="info" />
              )}
            </>
          )}

          {additionalRequestApproverId ===
            Additional_Request_Approver.AreaManager && (
            <>
              {additionalHoursData.status ===
                Additionalwork_status_types.DeclinedByAreaManager && (
                <HeaderBanner
                  title="Overview declined"
                  message="This overview has been sent back to the housekeeper because it is declined"
                  type="danger"
                />
              )}
              {additionalHoursData.isApproved ||
                (additionalHoursData.status ===
                  Additionalwork_status_types.ApprovedByAreaManager && (
                  <HeaderBanner title="Overview approved" type="info" />
                ))}
            </>
          )}
        </>
      )}

      {loading ||
      additionalHoursData.additionalRequestCategories.length === 0 ? (
        <Row className="text-center">
          {loading && <h4>Loading...</h4>}
          {additionalHoursData.additionalRequestCategories.length === 0 && (
            <h4>Administration has not yet been submitted</h4>
          )}
        </Row>
      ) : (
        <div>
          {additionalHoursData?.additionalRequestCategories.map(
            (category, ci) => {
              return (
                <>
                  <Row>
                    <Col lg={12}>
                      <h4>{category.categoryName}</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="fw-bolder" sm={3}>
                      Item
                    </Col>
                    <Col className="fw-bolder" sm={3}>
                      Amount
                    </Col>
                    <Col className="fw-bolder" sm={3}>
                      Total
                    </Col>
                    <Col className="fw-bolder" sm={3}>
                      Comments
                    </Col>
                  </Row>
                  <>
                    {category.additionalRequestItems.map((requestItem, ai) => {
                      return (
                        <>
                          <Row
                            className="mb-2"
                            key={`additionalRequestCategories[${ci}][${ai}]`}
                          >
                            <Col lg={3}>{requestItem.itemName}</Col>
                            <Col lg={3}>
                              {requestItem.quantity}{" "}
                              {getAmountType(requestItem)}
                            </Col>

                            <Col lg={3}>{requestItem.value}</Col>
                            <Col lg={3}>{requestItem.comment}</Col>
                          </Row>
                        </>
                      );
                    })}
                  </>
                  <hr></hr>
                  <Row className="mb-2">
                    <Col className="fw-bolder" sm={3}>
                      {category.categoryName}
                    </Col>
                    <Col className="fw-bolder" sm={3}>
                      Total amount
                    </Col>
                    <Col className="fw-bolder" sm={3}>
                      {category.value}
                    </Col>
                    <Col className="fw-bolder" sm={3}></Col>
                  </Row>
                </>
              );
            }
          )}

          {additionalHoursData && (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <h4>Info</h4>
                    {additionalRequestApproverId ===
                      Additional_Request_Approver.HotelManager && (
                      <Col sm={12}>
                        <div className="mb-3">
                          <AppTextInput
                            name="purchaseOrder"
                            label="Purchaseorder (is generated when you approve a regular overview)"
                            disabled={true}
                          />
                        </div>
                      </Col>
                    )}

                    {additionalHoursData.comment && (
                      <>
                        <h5 className="mt-3">Comments</h5>

                        <MessageTimeLineItem
                          value={additionalHoursData.comment}
                        />
                      </>
                    )}
                  </Row>

                  {!dataEntryDisabled() && (
                    <>
                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <AppTextAreaInput
                              name="comment"
                              label="Comment"
                              rows="6"
                              disabled={dataEntryDisabled()}
                            />
                          </div>
                        </Col>

                        <Col className="mb-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value="0"
                            onClick={(e) => {
                              setDataEntryInComplete(e.target.checked);
                            }}
                            id="dataEntryCorrect"
                          />

                          <label
                            for="dataEntryCorrect"
                            className="form-check-label ms-2"
                          >
                            To my knowledge, this form has been filled in
                            correctly
                          </label>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <AppSignaturepad
                              name="signature"
                              label="signature"
                              required={true}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="text-end pt-5">
                        <Col>
                          <AppButton
                            variant="cancel"
                            disabled={
                              saving ||
                              !dataEntryInComplete ||
                              !values.signature
                            }
                            className="me-3"
                            onClick={(e) => {
                              setFieldValue("isApproved", false);
                              handleSubmit(e);
                            }}
                            title="Decline additional request"
                          ></AppButton>
                          <AppButton
                            variant="submit"
                            disabled={
                              saving ||
                              !dataEntryInComplete ||
                              !values.signature
                            }
                            onClick={(e) => {
                              setFieldValue("isApproved", true);
                              handleSubmit(e);
                            }}
                            title="Approve additional request"
                            type="button"
                          ></AppButton>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </>
  );
};

export default AdditionalHoursOverviewSheet;
