import React, { useState } from "react";
import { Col, Form, Row } from "reactstrap";

import approvedLogo from "../../assets/media/circle-check-solid.png";
import outstandingLogo from "../../assets/media/spinner.png";
import declinedLogo from "../../assets/media/circle-xmark-solid.png";
import downloadLogo from "../../assets/media/download.png";
import speechLogo from "../../assets/media/speech.png";
import afasLogo from "../../assets/media/AFAS.png";
import netSuiteLogo from "../../assets/media/Netsuite.png";

import {
  getAfasExportByRange,
  getComments,
  getExportMatrix,
  getProjectAfasExport,
  getProjectExportToAfas,
  getProjectExportToNetSuite,
  getStatistics,
} from "../../services/hostDashboardService";
import {
  Comment,
  ExportAfasDataByCustomRangeInput,
  GetHostDashboardData,
} from "../../types";
import { useEffect } from "react";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import moment from "moment";
import AppDashboardAccordionItem from "../components/dashboards/AppDashboardAccordionItem";
import { getTenants } from "./../../services/tenantService";
import Select from "react-select";
import {
  getProjectAdditionalItemsForDropdown,
  getProjectItemsForDropdown,
  getProjects,
  getProjectStaypleaseItemsForDropdown,
} from "../../services/projectService";
import CustomAccordion from "../components/dashboards/CustomAccordion";
import { saveAs } from "file-saver";
import AppModal from "../components/Common/AppModal";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import AppButton from "../components/Common/AppButton";
import { Formik } from "formik";
import AppDateInput from "../components/forms/AppDateInput";
import * as Yup from "yup";
import AppSelectInput from "../components/forms/AppSelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import useWeekPart from "../../utility/hooks/useWeekPart";

const HostDashboard = () => {
  const [hostDashboardData, setHostDashboardData] =
    useState<GetHostDashboardData>();
  const [week, setWeek] = useState<number>();
  const [weekDescription, setWeekDescription] = useState<string>();
  const [year, setYear] = useState<number>();
  const [index, setIndex] = useState("");
  const [indexProject, setIndexProject] = useState("");
  const [hasSecondWeekPart, setHasSecondWeekPart] = useState<boolean>();
  const [weekPart, setWeekPart] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [tenantFilter, setTenantFilter] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState([]);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [exportingToAfas, setExportingToAfas] = useState(false);
  const [items, setItems] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [staypleaseItems, setStaypleaseItems] = useState([]);
  const [exportingToNetSuite, setExportingToNetSuite] = useState(false);

  const [exportingData, setExportingData] = useState(false);

  const [showCommentHistoryModal, setShowCommentHistoryModal] = useState(false);

  const [showExportModal, setShowExportModal] = useState(false);

  const [accordionData, setAccordionData] = useState<any>();

  const [comments, setComments] = useState<Comment[]>();
  const [dateInfo, calculateWeekPart] = useWeekPart();

  useEffect(() => {
    // Get current date
    const currentDate = moment();
  
    // If we're in the first ISO week, return the new year (next year)
    const year = currentDate.isoWeek() === 1 && currentDate.month() === 11
      ? currentDate.year() + 1
      : currentDate.year(); // Keep the current year otherwise
  
    fetchData(
      year,
      currentDate.isoWeek(),
      1,
      tenantFilter,
      projectFilter
    );
    getTenantDataForFilter();
    getProjectDataForFilter();
  }, []);
  

  async function fetchData(
    year: number,
    week: number,
    weekPart: number,
    tenantIds: number[],
    projectIds: number[]
  ) {
    if (year == null || week == null) {
      return;
    }

    setLoading(true);
    const result = await getStatistics(
      tenantIds,
      projectIds,
      year,
      week,
      weekPart
    );
    if (result) {
      setHostDashboardData(result.data);

      if (result.data.result[0]) {
        setHasSecondWeekPart(result.data?.result[0].hasSecondWeekPart);
        setWeekDescription(result.data.result[0].weekNumber);
      } else {
        setHasSecondWeekPart(false)
      }
    }
    setYear(year);
    setWeek(week);
    setWeekPart(weekPart);
    setLoading(false);
  }

  const handleDatePickerChange = (newDate) => {
    calculateWeekPart(newDate);
  };

  async function getTenantDataForFilter() {
    const result = await getTenants();

    let options = result.data.result.items.map(function (tenant) {
      return { value: tenant.id, label: tenant.name };
    });

    setTenants(options);
  }

  async function getProjectDataForFilter() {
    const result = await getProjects();

    let options = result.data.result.items.map(function (project) {
      return { value: project.id, label: project.name };
    });

    setProjects(options);
  }

  const getItemsDataForFilter = async (projectIds) => {
    await getProjectItemsForDropdown(projectIds).then((e) => {
      let options = e.data.result.items.map(function (item) {
        return { value: item.value, label: item.label };
      });

      setItems(options);
    });

    await getProjectStaypleaseItemsForDropdown(projectIds).then((e) => {
      let options = e.data.result.items.map(function (item) {
        return { value: item.value, label: item.label };
      });

      setStaypleaseItems(options);
    });

    await getProjectAdditionalItemsForDropdown(projectIds).then((e) => {
      let options = e.data.result.items.map(function (item) {
        return { value: item.value, label: item.label };
      });

      setAdditionalItems(options);
    });
  };

  function toggle(newIndex) {
    if (newIndex === index) {
      setIndex("");
    } else {
      setIndex(newIndex);
    }
  }

  function toggleProject(newIndex) {
    if (newIndex === indexProject) {
      setIndexProject("");
    } else {
      setIndexProject(newIndex);
    }
  }

  useEffect(() => {
   
    fetchData(
      dateInfo.selectedYear,
      dateInfo.selectedWeek,
      dateInfo.weekPart,
      tenantFilter,
      projectFilter
    );
  }, [dateInfo]);

  const handleNextPrevDate = (isPrevious: boolean) => {
    const newData = calculateNextPrevWeek(
      isPrevious,
      week!,
      weekPart!,
      hasSecondWeekPart!,
      year!
    );
    
    fetchData(
      newData.year!,
      newData.week,
      newData.weekPart,
      tenantFilter,
      projectFilter
    );
  };

  const handleChangeTenant = (value) => {
    setTenantFilter(value);
    const tenantIds = value.map((v) => v.value);
    setTenantFilter(tenantIds);

    fetchData(year!, week!, weekPart!, tenantIds, projectFilter);
  };

  const handleChangeProject = (value) => {
    setProjectFilter(value);
    const projectIds = value.map((v) => v.value);
    setProjectFilter(projectIds);

    fetchData(year!, week!, weekPart!, tenantFilter, projectIds);
  };

  const handleProjectCallback = (value) => {
    if (value) {
      getItemsDataForFilter(value);
    }
  };

  const exportCustomRangeValidationScheme = Yup.object().shape({
    projectIds: Yup.array().required().label("Projects"),
    itemIds: Yup.array().label("Items"),
    additionalItemIds: Yup.array().label("Items"),
    staypleaseItemIds: Yup.array().label("Items"),
    dateFrom: Yup.date().required().label("Date from"),
    dateTill: Yup.date().required().label("Date till"),
  });

  const exportCustomRange = (data: ExportAfasDataByCustomRangeInput) => {
    getAfasExportByRange(
      data.projectIds,
      data.dateFrom,
      data.dateTill,
      data.itemIds,
      data.additionalItemIds,
      data.staypleaseItemIds
    ).then((result) => {
      saveAs(
        new Blob([result.data]),
        `Export-AFAS-${moment(data.dateFrom).format("DD-MM-YYYY")}-${moment(
          data.dateTill
        ).format("DD-MM-YYYY")}.xlsx`
      );
    });
  };

  const handleExportToNetSuite = async (projectId) => {
    setExportingToNetSuite(true);
    // Force an immediate re-render after updating the state
    setHostDashboardData((prevState: any) => ({ ...prevState }));

    try {
      await getProjectExportToNetSuite(projectId, week!, weekPart!, year!);

      // Update the state
      setHostDashboardData((prevState: any) => {
        const updatedData = { ...prevState }; // Clone the previous state

        // Find the project and update its 'isExportedToNetSuite' status
        updatedData!.result!.forEach((tenant) => {
          tenant.projects = tenant.projects.map((project) => {
            if (project.projectId === projectId) {
              return { ...project, isExportedToAfas: true };
            }
            return project;
          });
        });

        return updatedData; // Return the updated state
      });
    } finally {
      setExportingToNetSuite(false);
    }
  };

  const showExportButtons = (
    project: GetHostDashboardData["result"][0]["projects"][0]
  ) => {
    if (
      project.isExportedToAfas == false &&
      project.numberOfDeclined === 0 &&
      project.numberOfOutstanding === 0 &&
      project.isExportedToAfas === false &&
      (project.isAdditionalRequestComplete ||
        project.hasAdditionalItemConfiguration == false)
    ) {
      return true;
    }
    return false;
  };

  const exportMatrix = () => {
    setExportingData(true);

    getExportMatrix(week!, weekPart!, year!).then((result) => {
      setExportingData(false);

      saveAs(new Blob([result.data]), `Export-matrix.xlsx`);
    });
  };

  useEffect(() => {
    if (hostDashboardData) {
      const tenantAndProjectData = hostDashboardData.result.map((tenant) => ({
        title: `${tenant.tenantName} - week ${weekDescription}`,
        id: tenant.tenantId,
        RightNavigation: (
          <>
            <div className="me-2">
              <span className="me-4">
                <img src={approvedLogo} alt="approved" />
                <span className="ms-2">{tenant.numberOfApproved}</span>
              </span>

              <span className="me-4">
                <img src={outstandingLogo} alt="outstanding" />
                <span className="ms-2">{tenant.numberOfOutstanding}</span>
              </span>
              <span>
                <img src={declinedLogo} alt="declined" />
                <span className="ms-2">{tenant.numberOfDeclined}</span>
              </span>
            </div>
          </>
        ),
        body: (
          <>
            <CustomAccordion
              titleColor="text-secondary"
              key={`tenant-${tenant.tenantId}`}
              open={indexProject}
              handleOpen={toggleProject}
              items={
                tenant.projects.map(
                  (project) =>
                    ({
                      title: `${project.projectName} (Week ${week})`,
                      id: `project-${project.projectId}`,
                      RightNavigation: (
                        <>
                          <div className="me-2">
                            <span className="me-3">
                              <img src={approvedLogo} />
                              <span className="ms-2">
                                {project.numberOfApproved}
                              </span>
                            </span>
                            <span className="me-3">
                              <img src={outstandingLogo} />
                              <span className="ms-2">
                                {project.numberOfOutstanding}
                              </span>
                            </span>
                            <span className="me-3">
                              <img src={declinedLogo} />
                              <span className="ms-2">
                                {project.numberOfDeclined}
                              </span>
                            </span>
                            |
                            <span className="ms-3" title="Show comment history">
                              <a
                                onClick={(e) => {
                                  e.stopPropagation();
                                  getComments(
                                    project.projectId,
                                    year!,
                                    week!,
                                    weekPart!
                                  );

                                  setShowCommentHistoryModal(
                                    !showCommentHistoryModal
                                  );
                                }}
                                rel="noopener noreferrer"
                              >
                                <img src={speechLogo} />
                              </a>
                            </span>
                            <span className="ms-3" title="Download AFAS report">
                              {downloadingFile ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <>
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDownloadingFile(true);
                                      getProjectAfasExport(
                                        project.projectId,
                                        week!,
                                        weekPart!,
                                        year!
                                      ).then((result) => {
                                        setDownloadingFile(false);

                                        saveAs(
                                          new Blob([result.data]),
                                          `Export-AFAS-${project.projectName}-${week}-${year}.xlsx`
                                        );
                                      });
                                    }}
                                    rel="noopener noreferrer"
                                  >
                                    <img src={downloadLogo} />
                                  </a>
                                </>
                              )}
                            </span>

                            {showExportButtons(project) && (
                              <span className="ms-3" title="Export to NetSuite">
                                {exportingToNetSuite ? (
                                  <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <a
                                    onClick={async (e) => {
                                      e.stopPropagation();
                                      await handleExportToNetSuite(
                                        project.projectId
                                      );
                                    }}
                    
                                    rel="noopener noreferrer"
                                  >
                                    <img height={37} src={netSuiteLogo} />
                                  </a>
                                )}
                              </span>
                            )}
                          </div>
                        </>
                      ),
                      body: (
                        <>
                          {project.items.map((day) => {
                            return (
                              <AppDashboardAccordionItem
                                date={day.date}
                                description={day.description}
                                statusType={day.statusType}
                              />
                            );
                          })}

                          {project.hasAdditionalItemConfiguration && (
                            <AppDashboardAccordionItem
                              date={null}
                              isAdditionalRequest={true}
                              description="Additional request"
                              statusType={project.additionalRequestStatus}
                            />
                          )}
                        </>
                      ),
                    } as any)
                ) as any
              }
            />
          </>
        ),
      }));

      setAccordionData(tenantAndProjectData);
    }
  }, [
    week,
    weekDescription,
    weekPart,
    indexProject,
    downloadingFile,
    exportingToAfas,
    hostDashboardData,
  ]);

  return (
    <>
      <PageTitle title="Dashboard" customStyles="mb-2" />

      <div className="d-flex justify-content-between">
        <div>
          <DateToggler
            title={
              hasSecondWeekPart || weekPart === 2 ? (
                <span className="mx-3">
                  Weekly overview - week {week}.{weekPart}
                </span>
              ) : (
                <span className="mx-3">Weekly overview - week {week}</span>
              )
            }
            onNextDate={() => handleNextPrevDate(false)}
            onPreviousDate={() => handleNextPrevDate(true)}
            handleDatePickerChange={(date) => {
              handleDatePickerChange(date);
            }}
          />
        </div>
        <div>
          <AppButton
            title="Export matrix"
            variant="export"
            loading={exportingData}
            className="btn btn-lg me-2"
            onClick={() => {
              exportMatrix();
            }}
          />

          <AppButton
            title="Export custom range"
            variant="export"
            onClick={() => setShowExportModal(true)}
          />

          <button
            type="button"
            className="btn btn-lg btn-secondary ms-2"
            onClick={() =>
              fetchData(year, week, weekPart, tenantFilter, projectFilter)
            }
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
        </div>
      </div>

      <Row className="pb-3">
        <Col lg={6} sm={6}>
          <h6>Tenant</h6>
          <Select isMulti options={tenants} onChange={handleChangeTenant} />
        </Col>
        <Col lg={6} sm={6}>
          <h6>Project</h6>
          <Select isMulti options={projects} onChange={handleChangeProject} />
        </Col>
      </Row>
      {loading && (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      )}

      <CustomAccordion
        key="tenant-accordion"
        open={index}
        handleOpen={toggle}
        items={accordionData}
      />

      {!loading && !hostDashboardData?.result[0] && (
        <Row className="text-center">
          <h4>Administration has not yet been submitted...</h4>
        </Row>
      )}

      <AppModal
        show={showCommentHistoryModal}
        title="Comment history"
        handleHide={() => {
          setShowCommentHistoryModal(!showCommentHistoryModal);
          setComments([]);
        }}
      >
        <>
          {!comments && <h5>There are no comments</h5>}
          {comments?.map((comment) => (
            <MessageTimeLineItem
              userName={comment.userName}
              value={comment.value}
              action={comment.type}
              creationTime={comment.creationTime}
            />
          ))}
        </>
      </AppModal>

      <AppModal
        show={showExportModal}
        title="Export with custom filters"
        handleHide={() => setShowExportModal(!showExportModal)}
      >
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              projectIds: [],
              itemIds: [],
              staypleaseItemIds: [],
              additionalItemIds: [],
            }}
            onSubmit={exportCustomRange}
            validationSchema={exportCustomRangeValidationScheme}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col lg={12}>
                    <AppSelectInput
                      label="Projects"
                      name="projectIds"
                      isMultiple
                      required
                      options={projects}
                      callback={handleProjectCallback}
                    />
                  </Col>
                </Row>

                {items.length > 0 && (
                  <Row className="mb-3">
                    <Col lg={12}>
                      <AppSelectInput
                        label="Items"
                        name="itemIds"
                        isMultiple
                        options={items}
                      />
                    </Col>
                  </Row>
                )}

                {staypleaseItems.length > 0 && (
                  <Row className="mb-3">
                    <Col lg={12}>
                      <AppSelectInput
                        label="HFC items"
                        name="staypleaseItemIds"
                        isMultiple
                        options={staypleaseItems}
                      />
                    </Col>
                  </Row>
                )}

                {additionalItems.length > 0 && (
                  <Row className="mb-3">
                    <Col lg={12}>
                      <AppSelectInput
                        label="Additional requests"
                        name="additionalItemIds"
                        isMultiple
                        options={additionalItems}
                      />
                    </Col>
                  </Row>
                )}

                <Row className="mb-3">
                  <Col lg={6}>
                    <AppDateInput name="dateFrom" label="Date from" required />
                  </Col>
                  <Col lg={6}>
                    <AppDateInput name="dateTill" label="Date till" required />
                  </Col>
                </Row>

                <Row className="text-end">
                  <Col>
                    <AppButton
                      title="Export"
                      color="primary"
                      size="lg"
                      loading={exportingData}
                      disabled={exportingData}
                      type="submit"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </>
      </AppModal>
    </>
  );
};

export default HostDashboard;
